// Standard library imports
import React from 'react';
// External library imports
import _ from 'lodash';
// Internal module imports
import BiddingInfoContent from '../../../Bidding/ModalContent/BiddingInfoContent';
import { AFRR_MARKET_TYPES } from '../../../../constants';

export const BidInfo = ({ market, bid, fetchBids, setOpen }) => {
    const renderComponent = () => {
        const data = {
            capacity: {
                overall: _.get(bid, ['capacity'], []),
                primary: _.get(bid, ['primary', 'capacity'], []),
                secondary: _.get(bid, ['secondary', 'capacity'], []),
            },
            price: {
                overall: _.get(bid, ['price'], []),
                primary: _.get(bid, ['primary', 'price'], []),
                secondary: _.get(bid, ['secondary', 'price'], []),
                ...(AFRR_MARKET_TYPES.includes(market) ? { regulationPrice: _.get(bid, ['primary', 'regulationPrice'], []) } : {}),
            },
            hourlyData: {
                bidStatus: _.get(bid, ['bidStatus'], []),
                bidId: bid.id,
            },
        };

        return <BiddingInfoContent market={market} data={data} setOpen={setOpen} fetchBids={fetchBids} />;
    };
    return <div>{renderComponent()}</div>;
};
