// Standard library imports
import React, { useEffect, useState } from 'react';
// external library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import { UilInfoCircle, UilSync, UilImport, UilCapture, UilKeyboardShow, UilTrash, UilBan, UilNotes } from '@iconscout/react-unicons';
import { MenuItem, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import Dropdown from '../../../components/Inputs/Dropdown';
import classes from '../../../styles/Bids.module.css';
import { useLoader } from '../../../hooks';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { Capitalize } from '../../../utils/stringHelper';
import CreateInstanceBundle from './ModalComponent/CreateInstanceBundle';
import { BundleBiddingService } from '../../../services/bundleBiddingService';
import CreateBundleBid from './ModalComponent/CreateBundleBid';
import CreateAgreement from '../CreateAgreement';
import DateRangePicker from '../../../components/Inputs/DateRangePicker/DateRangePicker';
import Table from '../../../components/Table/Table';
import { momentTimeFormater } from '../../../utils/dateHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import AlertModal from '../../../components/AlertModal/AlertModal';
import BiddingInfoContent from './ModalComponent/BiddingInfo';
import CreateSecondaryBid from './ModalComponent/CreateSecondaryBid';
import Status from './ModalComponent/Status';
import ResultId from './ModalComponent/ResultId';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { dkCurrencyFormat } from '../../../utils/currencyHelper';
import { Panic } from './ModalComponent/Panic';
import { Notes } from './ModalComponent/Notes';
import { NotesLogsIndex } from './ModalComponent/NotesLogsIndex';

const BundleBidding = ({ market, updateQueryParams, queryParamsData = {} }) => {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [instanceBundles, setInstanceBundles] = useState([]);
    const [selectedInstanceBundle, setSelectedInstanceBundle] = useState({});
    const [rawCustomers, setRawCustomers] = useState({});
    const [lecenseOptions, setLicenseOption] = useState([]);
    const [createInstanceBundleModal, setCreateInstanceBundleModal] = useState({
        status: false,
        data: null,
    });
    const [createBundleBidModal, setCreateBundleBidModal] = useState(false);
    const [aggrement, setAggrement] = useState({});
    const [aggrementModal, setAggrementModal] = useState({
        modalStatus: false,
        instance: [],
        link: '',
    });
    const date = new Date();
    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [bundleBids, setBundleBids] = useState([]);
    const [biddingInfoModal, setBiddingInfoModal] = useState({
        status: false,
    });
    const [alertModal, setAlertModal] = useState(false);
    const [dMinusOnemodal, setDMinusOneModal] = useState({
        status: false,
        bid: {},
    });
    const [statusModal, setStatusModal] = useState({
        status: false,
        id: {},
    });
    const [resultIdModal, setResultIdModal] = useState({
        status: false,
        data: {},
    });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        bidId: '',
        type: 'primary',
    });

    const [panicModal, setPanicModal] = useState({
        status: false,
        data: {},
    });
    const [notesModal, setNotesModal] = useState({
        status: false,
        data: {},
    });
    const [isParams, setIsParams] = useState(queryParamsData?.customer ? true : false);

    useEffect(() => {
        if (market) {
            fetchMarketEnabledGroups();
        }
    }, [market]);

    useEffect(() => {
        if (selectedInstanceBundle?.value) {
            getBundleBids();
        } else {
            setBundleBids([]);
        }
    }, [selectedInstanceBundle]);

    useEffect(() => {
        if (selectedCustomer.value) {
            getInstanceBundle();
        }
        if (selectedCustomer.value && Object.keys(rawCustomers).length) {
            const customer = rawCustomers[selectedCustomer.value];
            const licenses = customer.licenses;
            const activeLicenses = Object.keys(licenses).map((id) => ({
                label: Capitalize(licenses[id].type),
                value: id,
                instances: licenses[id].groups || [],
            }));
            setLicenseOption(activeLicenses);
        }
    }, [selectedCustomer]);

    const fetchMarketEnabledGroups = () => {
        BundleBiddingService.GetMarketEnabledGroups(market, startLoader, groupSuccess, handleError, stopLoader);
    };

    const groupSuccess = ({ data }) => {
        const res = data.data;
        setRawCustomers(res);
        const curCustomers = Object.keys(res);
        if (curCustomers.length) {
            const customerOptions = curCustomers.map((id) => ({
                label: res[id].name,
                value: id,
                biddingMarkets: res[id].biddingMarkets,
            }));
            setSelectedCustomer(isParams ? queryParamsData.customer : customerOptions[0]);
            setCustomers(customerOptions);
        } else {
            toast.error('No Instances Enabled!');
            setCustomers([]);
        }
    };

    const getInstanceBundle = () => {
        BundleBiddingService.GetInstanceBundle(selectedCustomer.value, { market }, startLoader, handleInstanceBundleSuccess, handleError, stopLoader);
    };

    const handleInstanceBundleSuccess = ({ data }) => {
        const temp = data?.data?.data.map((e) => ({
            label: e.name,
            value: e._id,
            pairs: e.pairs,
        }));
        setSelectedInstanceBundle(isParams ? queryParamsData.instanceBundle : temp[0] || {});
        setInstanceBundles(temp || []);
        setAggrement(data.data.aggrement || {});
        setIsParams(false);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const checkForAggrement = () => {
        let isAggrement = { status: true, instance: [] };
        selectedInstanceBundle.pairs.map((ins) => {
            if (!aggrement?.instances?.[ins.instanceId]) {
                isAggrement.status = false;
                isAggrement.instance.push(ins.instanceName);
            }
        });
        return isAggrement;
    };

    const redirectToRevenue = () => {
        const link = `/?tab=3&showRevenueSplit=${JSON.stringify({
            status: true,
            data: {
                _id: selectedCustomer?.value,
                biddingMarkets: selectedCustomer?.biddingMarkets || [],
            },
        })}`;
        return link;
    };

    const getBundleBids = () => {
        const params = {
            instanceBundleId: selectedInstanceBundle?.value,
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        };
        BundleBiddingService.GetBundleBids(params, startLoader, bundleBidSuccess, handleError, stopLoader);
    };

    const bundleBidSuccess = ({ data }) => {
        const proccessedData = data.data.data.map((item) => ({
            date: momentTimeFormater(item.date).format('DD-MM-YYYY'),
            avgCapacity: item.avgCapacity,
            revenue: item?.revenue ? dkCurrencyFormat(item.revenue) : '--',
            status: `${item.primaryStatus}/${item.secondaryStatus}`,
            action: (
                <div className={classes.ActionContainer}>
                    <CustomTooltip content={'Bidding Info'}>
                        <div
                            className={classes.Action}
                            onClick={() => {
                                let hourlyCost = {};
                                item.bidStatus?.length
                                    ? (hourlyCost = {
                                          bidStatus: item.bidStatus,
                                      })
                                    : setAlertModal('SETTLEMENTS');
                                setBiddingInfoModal({
                                    status: true,
                                    hourlyCost,
                                    bids: item.bids,
                                    overallCapacity: item.overallCapacity,
                                    overallPrice: item.overallPrice,
                                });
                            }}
                        >
                            <div>
                                <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </div>
                    </CustomTooltip>

                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <CustomTooltip content={'Download'}>
                            <div className={classes.Action} onClick={() => handleDownloadHourData(item)}>
                                <div>
                                    <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                        {(market === 'FCR-D-INC' || market === 'FCR-D-DEC') && _.get(item, ['secondary', 'isDeleted']) && (
                            <CustomTooltip content={'Secondary Bid'}>
                                <div className={classes.Action} onClick={() => setDMinusOneModal({ status: true, bid: item })}>
                                    <div>
                                        <UilSync size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                        )}

                        <CustomTooltip content={'Fetch Status'}>
                            <div className={classes.Action} onClick={() => setStatusModal({ id: item._id, status: true })}>
                                <div>
                                    <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Result ID'}>
                            <div className={classes.Action} onClick={() => setResultIdModal({ status: true, data: item })}>
                                <div>
                                    <UilKeyboardShow size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                    </div>

                    <Menu
                        menuButton={
                            <div>
                                <CustomTooltip content={'Delete'}>
                                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CustomTooltip>
                            </div>
                        }
                        direction={'left'}
                        arrow={true}
                    >
                        {item.primaryBid ? (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setDeleteModal({ status: true, bidId: item._id, type: 'primary' });
                                    }}
                                    disabled={_.get(item, ['primary', 'provider', 'bidStatus'], []).length ? true : false}
                                >
                                    <div>Delete Primary Bid {_.get(item, ['primary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                </MenuItem>
                                {item.secondaryBid ? <MenuDivider /> : ''}
                            </>
                        ) : (
                            ''
                        )}

                        {item.secondaryBid ? (
                            <MenuItem
                                onClick={() => {
                                    setDeleteModal({ status: true, bidId: item._id, type: 'secondary' });
                                }}
                                disabled={_.get(item, ['secondary', 'provider', 'bidStatus'], []).length ? true : false}
                            >
                                <div>Delete Secondary Bid {_.get(item, ['secondary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                            </MenuItem>
                        ) : (
                            ''
                        )}
                    </Menu>
                    {_.get(item, ['bidStatus'], []).length ? (
                        <CustomTooltip content={'Panic'}>
                            <div
                                className={classes.Action}
                                onClick={() => {
                                    setPanicModal({ status: true, data: item });
                                }}
                            >
                                <div>
                                    <UilBan size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                    ) : (
                        ''
                    )}

                    <CustomTooltip content={'Bid Note'}>
                        <div
                            className={classes.Action}
                            onClick={() => {
                                setNotesModal({ status: true, data: item });
                            }}
                        >
                            <div>
                                <UilNotes size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </div>
                    </CustomTooltip>
                </div>
            ),
            style: item?.notes?.length ? { background: '#fef6e5', color: '#faa500' } : !item?.bidStatus?.length ? { background: '#E6E8FA', color: '#4C5AD9' } : {},
        }));
        setBundleBids(proccessedData);
        setTotalRevenue(Math.round(data.data.totalRevenue).toLocaleString('da-DK') || 0);
    };

    const handleDownloadHourData = (item) => {
        if (item.market !== 'FFR') {
            let totalDMinus2Price = 0;
            let totalDMinus1Price = 0;
            let totalPrice = 0;

            let capacity = {};
            let secCapacity = {};

            let price = [];
            let secPrice = [];

            _.get(item, ['bids'], []).map((b, bIdx) => {
                _.set(capacity, [b.instanceId], _.get(b, ['primary', 'capacity'], []));
                _.set(secCapacity, [b.instanceId], _.get(b, ['secondary', 'capacity'], []));

                Array(24)
                    .fill(0)
                    .map((p, pIdx) => {
                        _.set(price, [pIdx], _.get(price, [pIdx], 0) + _.get(b, ['primary', 'price', pIdx], 0));
                        _.set(secPrice, [pIdx], _.get(secPrice, [pIdx], 0) + _.get(b, ['secondary', 'price', pIdx], 0));
                    });
            });

            const sumOfCapacity = getSumCapacity(capacity);
            const sumOfCapacityDMinus1 = getSumCapacity(secCapacity);

            let data = Array(24)
                .fill(null)
                .map((i, index) => {
                    const dMinus2price = _.get(item, ['primary', 'provider', 'bidStatus', index, 'price'], '--');
                    const dMinus1price = _.get(item, ['secondary', 'provider', 'bidStatus', index, 'price'], '--');
                    const hourPrice = _.get(item, ['bidStatus', index, 'price'], 0);

                    if (dMinus2price >= 0) totalDMinus2Price += dMinus2price;
                    if (dMinus1price >= 0) totalDMinus1Price += dMinus1price;
                    if (hourPrice >= 0) totalPrice += hourPrice;

                    return {
                        hour: index,
                        capacityDMinus2: _.get(sumOfCapacity, [index], '--'),
                        priceDMinus2: _.get(price, [index], '--'),
                        settlementDMinus2: dMinus2price,
                        capacityDMinus1: _.get(sumOfCapacityDMinus1, [index], '--'),
                        priceDMinus1: _.get(secPrice, [index], '--'),
                        settlementDMinus1: dMinus1price,
                        hourcost: hourPrice,
                    };
                });

            data.push({
                hour: 'Total',
                capacityDMinus2: '',
                priceDMinus2: '',
                settlementDMinus2: totalDMinus2Price,
                capacityDMinus1: '',
                priceDMinus1: '',
                settlementDMinus1: totalDMinus1Price,
                hourcost: totalPrice,
            });

            DownloadAsExcel(data, `FCR-D_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, [
                'Hour',
                'Capacity (MW) (D-2)',
                'Price (€) (D-2)',
                'Settlement (€) (D-2)',
                'Capacity (MW) (D-1)',
                'Pricem (€) (D-1)',
                'Selttlement (€) (D-1)',
                'Settlement (€) ( D-2 & D-1)',
            ]);
        } else {
            let data = _.get(item, ['overallCapacity'], []).map((i, index) => ({
                hour: index,
                capacity: i,
                price: _.get(item, ['bids', '0', 'price', index], '--'),
                hourcost: _.get(item, ['bidStatus', index, 'price'], '--'),
            }));
            DownloadAsExcel(data, `FFR_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
        }
    };

    const getSumCapacity = (capacity) => {
        let isCap = false;
        let data = Array(24)
            .fill(null)
            .map((i, index) => {
                let sum = 0;
                for (let key in capacity) {
                    if (capacity[key].length) {
                        sum += Number(capacity[key]?.[index]);
                        isCap = true;
                    }
                }
                return isCap ? sum : '--';
            });
        return data;
    };

    const minMaxValues = {
        min: _.minBy(bundleBids, 'avgCapacity') && _.minBy(bundleBids, 'avgCapacity').avgCapacity,
        max: _.maxBy(bundleBids, 'avgCapacity') && _.maxBy(bundleBids, 'avgCapacity').avgCapacity,
    };

    const handleDelete = (id) => {
        BundleBiddingService.DeleteBundleBid(deleteModal.bidId, { type: deleteModal.type }, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        getBundleBids();
        toast.success('Bid Deleted');
    };

    return (
        <div>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent isOpen={resultIdModal.status} setOpen={() => setResultIdModal({ status: false, data: {} })}>
                <ResultId bid={resultIdModal.data} setModal={setResultIdModal} getBundleBids={getBundleBids} />
            </ModalComponent>
            <ModalComponent isOpen={statusModal.status} setOpen={(e) => setStatusModal({ status: false })}>
                <Status bundleBidId={statusModal.id} setOpen={setStatusModal} getBundleBids={getBundleBids} />
            </ModalComponent>
            <ModalComponent
                isOpen={biddingInfoModal.status}
                setOpen={() => {
                    setBiddingInfoModal({ status: false });
                }}
            >
                <BiddingInfoContent setOpen={setBiddingInfoModal} data={biddingInfoModal} />
            </ModalComponent>
            <ModalComponent isOpen={dMinusOnemodal.status} setOpen={() => setDMinusOneModal({ status: false, bid: {} })}>
                {dMinusOnemodal.status && (
                    <CreateSecondaryBid
                        bid={dMinusOnemodal.bid}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        setOpen={(e) => setDMinusOneModal({ status: false, bid: {} })}
                        getBundleBids={getBundleBids}
                    />
                )}
            </ModalComponent>
            <AlertModal title={alertModal} isOpen={alertModal} content={'No Data Found!'} setOpen={setAlertModal} />
            <ModalComponent
                isOpen={aggrementModal.modalStatus}
                setOpen={() => {
                    setAggrement({ ...aggrementModal, modalStatus: false });
                }}
            >
                <CreateAgreement link={aggrementModal.link} instance={aggrementModal.instance} setOpen={() => setAggrementModal({ modalStatus: false })} />
            </ModalComponent>
            <ModalComponent isOpen={createBundleBidModal} setOpen={setCreateBundleBidModal} style={{ overflow: 'initial' }}>
                <div>
                    <CreateBundleBid
                        instanceBundle={selectedInstanceBundle}
                        customerId={selectedCustomer.value}
                        setOpen={setCreateBundleBidModal}
                        market={market}
                        getBundleBids={getBundleBids}
                    />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={createInstanceBundleModal.status} setOpen={() => setCreateInstanceBundleModal({ status: false, data: null })} style={{ overflow: 'initial' }}>
                <div>
                    <CreateInstanceBundle
                        market={market}
                        customer={selectedCustomer}
                        licenses={lecenseOptions}
                        setOpen={setCreateInstanceBundleModal}
                        getInstanceBundle={getInstanceBundle}
                        editData={createInstanceBundleModal.data}
                    />
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={panicModal.status}
                setOpen={() => {
                    setPanicModal({ status: false, data: {} });
                }}
            >
                <Panic data={panicModal.data} setOpen={setPanicModal} getBundleBids={getBundleBids} />
            </ModalComponent>
            <ModalComponent
                isOpen={notesModal.status}
                setOpen={() => {
                    setNotesModal({ status: false, data: null });
                }}
            >
                <NotesLogsIndex data={notesModal.data} setOpen={setNotesModal} />
            </ModalComponent>
            <div className={classes.Bids}>
                <div className={classes.Header}>
                    <div style={{ gridColumnGap: '1vw' }}>
                        <Typography content="Bundle Bids" />
                        <div className={classes.TableCount}>
                            Total Revenue :
                            <span>
                                <Typography size="14" content={totalRevenue + ' €'} />
                            </span>
                        </div>
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={bundleBids?.length || 0} />
                            </span>
                        </div>
                        <Formik enableReinitialize initialValues={{ customerId: '', bundleBid: '' }}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form style={{ display: 'flex', gridColumnGap: '0.3vw' }}>
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="customerId"
                                            options={customers}
                                            defaultValue={selectedCustomer}
                                            onChange={(e) => {
                                                if (e.value !== selectedCustomer?.value) {
                                                    setSelectedCustomer(e);
                                                }
                                                updateQueryParams('customer', JSON.stringify(e));
                                                updateQueryParams('instanceBundle', '');
                                            }}
                                        />
                                    </div>

                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="bundle"
                                            options={instanceBundles}
                                            defaultValue={selectedInstanceBundle}
                                            onChange={(e) => {
                                                if (e.value !== selectedInstanceBundle?.value) {
                                                    setSelectedInstanceBundle(e);
                                                }
                                                updateQueryParams('instanceBundle', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className={classes.ButtonsContainer}>
                        {selectedInstanceBundle?.value && (
                            <div
                                onClick={() => {
                                    setCreateInstanceBundleModal({ status: true, data: selectedInstanceBundle });
                                }}
                            >
                                <button style={{ width: '7vw', margin: 0 }} className="btn-primary" type="button">
                                    Edit Bundle
                                </button>
                            </div>
                        )}
                        <div
                            onClick={() => {
                                setCreateInstanceBundleModal({ status: true, data: null });
                            }}
                        >
                            <button style={{ width: '7vw', margin: 0 }} className="btn-primary" type="button">
                                Create Bundle
                            </button>
                        </div>
                        <div
                            onClick={() => {
                                if (selectedInstanceBundle?.value) {
                                    const data = checkForAggrement();
                                    if (data.status) {
                                        setCreateBundleBidModal(true);
                                    } else {
                                        toast.error('No Agreement Found.');
                                        setAggrementModal({
                                            modalStatus: true,
                                            link: redirectToRevenue(),
                                            instance: data.instance,
                                        });
                                    }
                                } else {
                                    toast.error('Instance bundle for found.');
                                }
                            }}
                        >
                            <button style={{ width: '7vw', margin: 0 }} className="btn-primary">
                                Create Bid
                            </button>
                        </div>
                    </div>
                </div>

                <div className={classes.SubHeader}>
                    <div className={classes.Badges}>
                        <div className={classes.BadgeContainer}>
                            <Typography content="Max Capacity" />
                            <div className={classes.TableCount}>
                                <Typography size="14" content={minMaxValues.max} />
                            </div>
                        </div>
                        <div className={classes.BadgeContainer}>
                            <Typography content="Min Capacity" />
                            <div className={classes.TableCount}>
                                <Typography size="14" content={minMaxValues.min} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.DatePickers}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={classes.DateLabels}>Start Date</div>
                                <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                    End Date
                                </div>
                            </div>
                            <DateRangePicker
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={(date) => {
                                    setDateRange({
                                        startDate: new Date(date.startDate),
                                        endDate: new Date(date.endDate),
                                    });
                                    updateQueryParams('dateRange', JSON.stringify(date));
                                }}
                            />
                        </div>
                        <div className={classes.SubmitButton} onClick={getBundleBids}>
                            <button className="btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
                <Table
                    head={['Date', 'Avg Capacity', 'Revenue', 'Status(Primary/Secondary)', 'Action']}
                    keys={['date', 'avgCapacity', 'revenue', 'status', 'action']}
                    data={bundleBids}
                />
            </div>
        </div>
    );
};

export default BundleBidding;
